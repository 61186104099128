<template>
  <div class="whpcSubmission">
    <LincolnEffortlessExperienceLogoDark />
    <div class="form-title">Add Story</div>

    <form @submit.prevent="submit">
      <TextBox :label="'Subject'"
               :name="'subject'"
               :required="true"
               :placeholder="'Subject *'"
               v-model="story.subject"
               class="textBox" />

      <div class="storyBody">
        <RichTextBox @update="updateBody" :initialContent="story.body" />
      </div>

      <ImageCropperForStory
        class="imagePreviewer"
        :storyData="story"
        @updatedImage="updatedImage"
      />

      <ButtonSubmit
        :copy="'Send Now'"
        @callback="submit"
      />
      <ButtonCancel class="cancel" @click="closeModal" />

    </form>
  </div>
</template>

<script>
import RichTextBox from '../../formElements/RichTextBox.vue';
import TextBox from '../../formElements/TextBox.vue';
import LincolnEffortlessExperienceLogoDark from '../../LincolnEffortlessExperienceLogoDark.vue';
import ImageCropperForStory from '../../imageManipulation/ImageCropperForStory.vue';
import ButtonSubmit from '../../formElements/buttons/buttonSubmit.vue';
import ButtonCancel from '../../formElements/buttons/ButtonCancel.vue';

export default {
  name: 'WhpcSubmission',
  components: {
    RichTextBox,
    TextBox,
    LincolnEffortlessExperienceLogoDark,
    ImageCropperForStory,
    ButtonSubmit,
    ButtonCancel,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    onStoryEdited: {
      type: Function,
      default: () => { },
    },
  },
  data() {
    return {
      story: {
        subject: '',
        body: '',
        thumbnailAssetId: null,
      },
    };
  },
  methods: {
    uploadAttachments() {
      // TODO: implement uploading attachments with a brand story
    },
    updateBody(html) {
      this.story.body = html;
    },
    async submit() {
      if (this.story.thumbnailAsset) {
        delete this.story.thumbnailAsset;
      }
      if (this.thumbnailAssetId) {
        this.story.thumbnailAssetId = this.thumbnailAssetId;
      }

      await this.$store.dispatch('whpcStore/submitStory', this.story);
      this.$analytics.trackEventBtnPage('WHPCSubmit');
      this.closeModal();
    },
    closeModal() {
      this.$modal.hide(this.name);
    },
    async updatedImage(file) {
      const thumbnailImageUploaded = await this.$store.dispatch('assetStore/setAsset', file);
      this.thumbnailAssetId = thumbnailImageUploaded.id;
    },
  },
};
</script>

<style lang="scss" scoped>
  .whpcSubmission {
    padding: 2em;
    background-color: white;

    .form-title {
      @include font-size(2rem);
    }

    .status {
      display: block;
      background: white;
      color: $LincolnGrayDark;
      border: 1px solid $LincolnGrayDark;
      padding: 10px;
      margin: 0 1em 1em 1em;

      .label {
        display: inline-block;
        font-size: 75%;
        width: 20%;
        line-height: 3em;
      }

      .control {
        width: 80%;
      }
    }

   .storyBody {
      display: block;
      background: white;
      color: $LincolnGrayDark;
      border: 1px solid $LincolnGrayDark;
      padding: 10px 10px 0px;
      margin: 2em 0;
    }

    .imagePreviewer {
      display: block;
      background: white;
      color: $LincolnGrayDark;
      border: 1px solid $LincolnGrayDark;
      padding: 10px 10px 0px;
      margin: 1em;
    }

    .imagePreviewer {
      display: block;
      background: white;
      color: $LincolnGrayDark;
      border: 1px solid $LincolnGrayDark;
      padding: 10px 10px 0px;
      margin: 1em;
    }
  }

  .options {
    color: $LincolnGrayLight;
  }

  .textBox {
    margin: 2em 0;
    display: block;
  }

  .cancel {
    margin-left: 1rem;
  }
</style>
