<template>
  <div class="content-wrapper">
    <div class="stories">
      <WhpcList v-if="!id" />
      <WhpcView v-else :id="id" />
    </div>
  </div>
</template>

<script>
import WhpcList from '../components/stories/whpc/list/WhpcList.vue';
import WhpcView from '../components/stories/whpc/view/WhpcView.vue';

export default {
  name: 'Stories',
  props: ['id'],
  components: {
    WhpcList,
    WhpcView,
  },
  async created() {
    this.$analytics.trackPageView();
    this.$store.dispatch('whpcStore/getStories');
  },
};
</script>
