<template>
  <div class="whpcList">
    <div class="pgTitle">Warm, Human & Personally Crafted Stories</div>
    <div class="addBtn" v-if="isAdmin" @click="openModal">
      <div class="txt">add new story</div>
      <font-awesome-icon class="icon" :icon="['fas', 'file-plus']" />
    </div>
    <LoadingOverlay v-show="showLoader" />
    <masonry
      :cols="{default: 7, 600: 2, 768: 3, 992: 4, 1200: 5}"
      :gutter="{default: '15px'}"
      :key="key"
    >
      <WhpcTile
        v-for="(story, index) in this.stories"
        :key="'story' + story.id"
        :story="story"
        :index="index"
      />
    </masonry>
  </div>
</template>

<script>
import WhpcTile from './WhpcTile.vue';
import WhpcSubmission from '../WhpcSubmission.vue';
import LoadingOverlay from '../../../loaders/LoadingOverlay.vue';

export default {
  name: 'WhpcList',
  components: {
    WhpcTile,
    LoadingOverlay,
  },
  async created() {
    if (this.stories.length === 0) {
      this.showLoader = true;
    }
    await this.$store.dispatch('whpcStore/getPublishedStories');
    this.showLoader = false;
  },
  data() {
    return {
      key: true,
      showLoader: false,
    };
  },
  methods: {
    rerenderMasonry() {
      this.key = !this.key;
    },
    openModal() {
      const name = 'WhpcSubmission';

      this.$modal.show(
        WhpcSubmission,
        { name },
        {
          name,
          adaptive: true,
          height: 'auto',
          width: '70%',
          scrollable: true,
        },
      );
    },
  },
  computed: {
    cols() {
      const defaultCols = this.whpcs.length === 2 ? 2 : 3;

      return {
        default: defaultCols,
        990: 2,
        650: 1,
      };
    },
    stories() {
      return this.$store.getters['whpcStore/publishedStories'];
    },
    isAdmin() {
      return this.$store.getters['userStore/isAdmin'];
    },
  },
};
</script>

<style lang="scss" scoped>
.whpcList {
  .pgTitle {
    text-align: center;
    @include font-size(2.875rem);
    margin: 10px 0 20px 0;
  }

  .addBtn {
    transition: all 0.35s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    @include font-size(1.25rem);
    text-transform: uppercase;
    margin: 40px 0;

    @include respond(md) {
      justify-content: flex-start;
    }

    &:hover {
      cursor: pointer;
      color: $LincolnOrange;
    }

    .txt {
      margin-right: 10px;
    }
  }
}
</style>
