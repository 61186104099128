<template>
  <div>
    <button :class="{
        sized: !allowSizing,
    }"
    @click.prevent="click">
      {{copy}}
    </button>
  </div>
</template>

<script>
export default {
  name: 'ButtonCancel',
  props: {
    copy: {
      type: String,
      required: false,
      default: 'Cancel',
    },
    allowSizing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    click() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  display: inline-block;

  button {
    border: none;
    padding: 1em 2em 1em 2em;
    @include font-size(1rem);
    text-transform: uppercase;
    background-color: $LincolnGrayDark;
    color: white;
    cursor: pointer;

    &.sized {
      width: $buttonWidth;
    }
  }
}
</style>
