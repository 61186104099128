<template>
  <div v-if="story" class="storyViewImage">
    <div
      class="img"
      :style="setBackgroundImage"></div>
  </div>
</template>

<script>
export default {
  name: 'StoryViewImage',
  props: ['story'],
  data() {
    return {
      downloadedImage: null,
    };
  },
  async created() {
    this.fetchImage(this.story);
  },
  watch: {
    story(storyNew) {
      this.fetchImage(storyNew);
    },
  },
  methods: {
    async getImage(id) {
      const asset = await this.$store.dispatch(
        'assetStore/downloadAsset',
        id,
      );
      if (asset) {
        this.downloadedImage = window.URL.createObjectURL(new Blob([asset.data]));
      }
    },
    async fetchImage(storyObject) {
      if (storyObject && storyObject.thumbnailAsset) {
        await this.getImage(storyObject.thumbnailAsset.id);
      }
    },
  },
  computed: {
    setBackgroundImage() {
      let tempPath = '';
      const backgroundImage = {};
      if (this.downloadedImage) {
        tempPath = this.downloadedImage;
      }
      backgroundImage['background-image'] = `url(${tempPath})`;
      return backgroundImage;
    },
  },
};
</script>

<style lang="scss" scoped>
.storyViewImage {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  .img {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;

    background-color: $LincolnGrayLighter;

    height: 300px;
    width: 250px;
  }

}
</style>
