import { render, staticRenderFns } from "./StoryViewImage.vue?vue&type=template&id=249e33e9&scoped=true"
import script from "./StoryViewImage.vue?vue&type=script&lang=js"
export * from "./StoryViewImage.vue?vue&type=script&lang=js"
import style0 from "./StoryViewImage.vue?vue&type=style&index=0&id=249e33e9&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "249e33e9",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("c:\\VSTSAgentPreProd\\_work\\103\\s\\effortless-guide-site\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('249e33e9')) {
      api.createRecord('249e33e9', component.options)
    } else {
      api.reload('249e33e9', component.options)
    }
    module.hot.accept("./StoryViewImage.vue?vue&type=template&id=249e33e9&scoped=true", function () {
      api.rerender('249e33e9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/stories/common/StoryViewImage.vue"
export default component.exports