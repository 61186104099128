<template>
  <div class="story">
    <StoryViewImage :story="story" />
    <div class="divider-container">
      <div class="divider"></div>
    </div>
    <div v-if="story" class="storyViewCopy">
      <div class="title">{{story.subject}}</div>
      <div class="article" v-html="story.body" v-openLinksInNewTab></div>
    </div>
  </div>
</template>
<script>
import StoryViewImage from '../../common/StoryViewImage.vue';
import openLinksInNewTab from '../../../../directives/openLinksInNewTab';

export default {
  name: 'WhpcView',
  components: {
    StoryViewImage,
  },
  directives: {
    openLinksInNewTab,
  },
  props: ['id'],
  async mounted() {
    await this.$store.dispatch('whpcStore/getStory', this.id);
    const analytic = {
      analyticType: 'WHPCView',
      resourceId: this.id,
      userId: this.$store.getters['userStore/userId'],
    };
    this.$store.dispatch('analyticStore/add', analytic);
  },
  computed: {
    story() {
      return this.$store.getters['whpcStore/currentStory'];
    },
  },
};
</script>
<style lang="scss" scoped>
.story {
  display: flex;
  flex-direction: column;
  color: rgb(50, 64, 74);

  @include respond(md) {
    flex-direction: row;
  }

  .divider-container {
    display: none;

    @include respond(md) {
      display: block;
      padding: 0 4%;
    }

    .divider {
      width: 4px;
      padding: 0;
      background: rgb(35, 31, 32);
      height: 300px;
    }
  }

  .storyViewCopy {
    flex: 1;

    .title {
      @include font-size(2.875rem);
      margin: 20px 0 30px 0;
    }
  }
}
</style>
