var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "button",
      {
        class: {
          sized: !_vm.allowSizing,
        },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.click($event)
          },
        },
      },
      [_vm._v("\n    " + _vm._s(_vm.copy) + "\n  ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }